<template>
  <div class="w-full bg-white mt-4 p-4">
    <div class="w-full flex justify-between">
      <div>
        <p v-if="$route.params.id" class="text-blue-800 text-lg font-bold">
          Editar Area
        </p>
        <p v-else class="text-blue-800 text-lg font-bold">
          Crear Area
        </p>
      </div>
    </div>
    <div class="w-full grid gap-2 ">
      <div class="w-full py-4 grid-cols-5 grid gap-2">
        <div class="col-span-4 rounded-md border p-4 h-full">
          <div class="grid gap-2">
            <div class=" my-1 flex-col">
              <label for="name" class="text-sm text-gray-600">Nombre del area:</label>
              <InputText class="w-full" type="text" id="name" v-model="model.name"/>
              <MessageError :text="errors.name"/>
            </div>
          </div>
          <div class="grid gap-2 my-1">
            <div class="w-full my-1" v-if="!model.areaPrincipal">
              <label for="createdAt" class="text-sm text-gray-600">Area Principal:</label>
              <Dropdown
                v-model="model.parent_area_id"
                :options="areasPrincipales"
                optionValue="idAreaPrincipal"
                optionLabel="name"
                :filter="true"
                placeholder="Seleccione un area..."
                style="display: flex;"
              />
              <MessageError :text="errors.parent_area_id"/>
            </div>
            <div class="w-full my-1">
              <label for="encargado_id" class="text-sm text-gray-600">Encargado:</label>
              <Dropdown
                v-model="model.encargado_id"
                :options="encargados"
                optionValue="idEncargado"
                optionLabel="full_name"
                :filter="true"
                placeholder="Seleccione un encargado..."
                style="display: flex;"
              />
              <MessageError :text="errors.encargado_id"/>
            </div>
          </div>
        </div>
        <div class="rounded-md border p-4 h-full flex justify-center">
          <div class="my-1 items-center">
            <div v-if="$route.params.id">
              <label for="state" class="text-sm text-gray-600">Estado:</label>
              <div class="flex flex-col pb-4">
                <div class="align-center flex">
                  <InputSwitch v-model="model.state" class="show-code form-check-switch mr-0 ml-3"/>
                  <span class="ml-5">{{ model.state ? 'Activo' : 'Inactivo' }}</span>
                </div>
                <MessageError :text="errors.state"/>
              </div>
            </div>
            <label for="type" class="text-sm text-gray-600">Tipo:</label>
            <div class="flex flex-col">
              <div class="align-center flex">
                <InputSwitch v-model="model.areaPrincipal" class="show-code form-check-switch mr-0 ml-3"/>
                <span class="ml-5">{{ model.areaPrincipal ? 'Area' : 'Sub - area' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end" v-if="$route.params.id">
      <Button type="button"
              v-if="$route.params.id"
              icon="pi pi-check-circle"
              label="Editar area"
              class="p-button-sm"
              @click="onEdit()"/>
    </div>
    <div class="flex justify-end" v-else>
      <Button type="button"
              icon="pi pi-check-circle"
              label="Crear area"
              class="p-button-sm"
              @click="onSubmit()"/>
    </div>
    <div>
      <Button type="button"
              icon="pi pi-arrow-left"
              label="Regresar"
              class="p-button-sm"
              @click="$router.go(-1)"/>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import AreasService from '@/apps/pharmasan/recursosHumanos/services_back_new/areas.service'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'crearArea',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const _AreasService = new AreasService()
    const schema = yup.object({
      name: yup.string().required('El nombre del area es requerido').label(''),
      state: yup.boolean().required('La fecha de recepción es requerido').label(''),
      areaPrincipal: yup.boolean().label(''),
      parent_area_id: yup.number().when('areaPrincipal', {
        is: false,
        then: yup.number().required('El area principal de el area es requerido'),
        else: yup.number().label('')
      }),
      encargado_id: yup.number().required('El encargado del area es requerido').label(''),
      idAreaPrincipal: yup.number().label('')
    })
    const { errors, values: model, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    useField('name')
    useField('state', null, { initialValue: true })
    useField('areaPrincipal', null, { initialValue: false })
    useField('encargado_id')
    useField('parent_area_id')
    const encargados = ref([])
    const areasPrincipales = ref([])

    const getAreas = () => {
      _AreasService.getAreasPrincipales().then(({ data }) => {
        areasPrincipales.value = data
      })
    }
    const getEncargado = () => {
      _AreasService.getEncargadosArea().then(({ data }) => {
        encargados.value = data
      })
    }
    const onSubmit = handleSubmit((values) => {
      if (!values) return
      let areaTemp = ''
      if (!model.areaPrincipal) {
        areaTemp += 'el sub area'
      } else {
        areaTemp += 'el area'
      }
      Swal.fire({
        title: '¿Esta seguro?',
        html: `¿Desea crear ${areaTemp} : <strong>${model.name}</strong>?`,
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, crear',
        cancelButtonText: 'No, cancelar'
      }).then((response) => {
        if (response.isConfirmed) {
          _AreasService.postArea(values).then(({ data }) => {
            Swal.fire({
                title: 'Éxito',
                text: 'Se registro el area correctamente.',
                icon: 'success'
              }
            ).then(() => {
              handleReset()
              router.push({ name: 'pharmasan.recursos-humanos.configuracion.areas.listado' })
            })
          })
        }
      })
    })
    const onEdit = handleSubmit((values) => {
      if (!values) return
      let areaTemp = ''
      if (!model.areaPrincipal) {
        areaTemp += 'el sub area'
      } else {
        areaTemp += 'el area'
      }
      Swal.fire({
        title: '¿Esta seguro?',
        html: `¿Desea actualizar ${areaTemp} : <strong>${model.name}</strong>?`,
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, actualizar',
        cancelButtonText: 'No, cancelar'
      }).then((response) => {
        if (response.isConfirmed) {
          const payload = {
            id: route.params.id,
            data: values
          }
          _AreasService.putArea(payload).then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: 'Éxito',
                text: 'Se modificó el area correctamente.',
                icon: 'success'
              })
            }
          })
        }
      })
    })
    const editar = ({ id }) => {
      _AreasService.getAreaById(id).then(({ data }) => {
        if (!data.parent_area_id) {
          delete model.parent_area_id
          model.name = data.name
          model.state = data.state
          model.encargado_id = data.encargado_id
          model.areaPrincipal = !data.parent_area_id
        } else {
          model.name = data.name
          model.state = data.state
          model.encargado_id = data.encargado_id
          model.parent_area_id = data.parent_area_id
          model.areaPrincipal = !data.parent_area_id
        }
      })
    }
    onMounted(() => {
      if (route.params.id) {
        editar(route.params)
      }
      getEncargado()
      getAreas()
    })
    return {
      onSubmit,
      model,
      encargados,
      areasPrincipales,
      getEncargado,
      getAreas,
      errors,
      handleSubmit,
      editar,
      onEdit
    }
  }
}
</script>

<style scoped>

</style>
